import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import { ProductSection } from "./productSection.component"
import { useEventProductTracking, useViewPageTracking } from "../thirdParties/gtm/tagManager"
import SeoSchema from "../seo/seoSchema.component"
import { usePrice } from "./product.utils"
import { useEndPriceValidityDateHook } from "../website/useEndPriceValidityDate.hook"
import { useOverlayAddBottomComponent } from "../structure/overlay.provider"
import { useProductStickyButton } from "./useProductStickyButton"
import { useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"
import DynamicBlock from "../../components/block/dynamicBlock.component"
import { isWysiwygEmpty } from "../../utils/wysiwyg"

const ProductPageSticky = loadable(() => import(`./productPageSticky.component`), { ssr: false })

export default function ProductPage ({ data: { product, allFaq } }) {
  const {
    housingType,
    universes,
    linkedProducts,
    hookTitle,
    name,
    metaDescription,
    visual,
    upsellProduct,
    upsellHook,
    upsellLinkHook,
  } = product
  const { t } = useTranslation()
  const { resolveRoute } = useNavigation()
  const [ref, sticky] = useProductStickyButton()

  useOverlayAddBottomComponent(
    () => ({
      Component: ProductPageSticky,
      props: { product },
      isVisible: sticky,
      bottomSpace: 90,
    }),
    [product, sticky],
  )

  useViewPageTracking(universes[0].name, housingType, `Fiche Produit`)
  useEventProductTracking(product)

  const { price } = usePrice(product)
  const endPriceValidityDate = useEndPriceValidityDateHook(`yyyy-mm-dd`)

  const upsellLink = useMemo(() => {
    if (!upsellProduct) {
      return ``
    }

    return resolveRoute(`product`, upsellProduct)
  }, [upsellProduct, resolveRoute])

  return (
    <>
      <SeoSchema
        name="product"
        productName={name}
        metaDescription={metaDescription}
        url={resolveRoute(`product`, product)}
        imageUrl={visual.file.publicURL}
        price={price}
        priceValidUntil={endPriceValidityDate}
      />
      <ProductSection
        htmlTitle={hookTitle}
        product={product}
        refPaymentButton={ref}
        upsellProduct={upsellProduct}
        upsellLink={upsellLink}
        upsellLinkHook={upsellLinkHook}
        upsellHook={upsellHook}
      />
      <DynamicBlock
        __component="ThreeSteps"
        data-testid="three_steps"
        blockTitle={t(`common:information.section_three_steps_title`)}
      />
      <DynamicBlock
        __component="ProductDescription"
        blockTitle={t(`common:product.section_description`)}
        blockAnchor="productDescription"
        product={product}
      />
      <DynamicBlock
        __component="SchemaCover"
        data-testid="schema_block"
        blockTitle={
          isWysiwygEmpty(product.coverTitle) ? t(`common:product.section_schema_image_alt`) : product.coverTitle
        }
        blockAnchor="schemaCover"
        product={product}
        hasTag
      />

      <DynamicBlock
        __component="Faq"
        data-testid="faq_block"
        blockTitle={t(`common:product.section_faq_title`)}
        questions={allFaq.nodes}
        double
      />

      <DynamicBlock
        __component="Warranties"
        data-testid="warranties_block"
        blockTitle={t(`common:product.section_warranties_title`)}
      />

      <DynamicBlock
        __component="ProductBlock"
        data-testid="product_block_recommendation"
        blockContentWidth="large"
        blockTitle={t(`common:product.section_products_title`)}
        blockTitleStyle="fancy"
        products={linkedProducts}
        withPrice
        withLogo
      />
    </>
  )
}

ProductPage.propTypes = {
  data: PropTypes.shape({
    product: PropTypes.shape({
      originalId: PropTypes.number.isRequired,
      visual: PropTypes.shape({
        file: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      housingType: PropTypes.string.isRequired,
      universes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        }),
      ).isRequired,
      impactProduct: PropTypes.object.isRequired,
      interventions: PropTypes.array.isRequired,
      coverTitle: PropTypes.string,
      hookTitle: PropTypes.string,
      shortDescription: PropTypes.string.isRequired,
      longDescription: PropTypes.string.isRequired,
      uncoveredGuarantee: PropTypes.string,
      linkedProducts: PropTypes.array.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      metaDescription: PropTypes.string.isRequired,
      upsellProduct: PropTypes.object,
      upsellLinkHook: PropTypes.any,
      upsellHook: PropTypes.any,
    }).isRequired,
    allFaq: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query ProductById($id: String!, $tagRegex: String!) {
    product(id: { eq: $id }) {
      ...productPageAndSimpleCampaign
      upsellHook
      upsellLinkHook
      upsellProduct {
        slug
        ...upsellProduct
      }
    }
    allFaq(
      filter: { inlineTags: { regex: $tagRegex }, channels: { elemMatch: { name: { eq: "Site core - Produit" } } } }
    ) {
      nodes {
        id
        question
        answer
        position
      }
    }
  }
`
